<template>
  <Form :validation-schema="schema" v-slot="{ resetForm, handleSubmit }">
    <div class="grid grid-cols-1 sm:grid-cols-2 gap-6 mt-4 text-gray-900">
      <div class="relative text-left sm:text-md 2xl:text-xl">
        <label for="name" class="" > Nombre completo: </label>
        <Field
          name="name"
          class="form-input w-full mt-2 rounded-md sm:text-md 2xl:text-xl border-gray-500"
          v-model="user.nombre"
        />
        <ErrorMessage name="name" class="absolute text-sm text-red-600 inset-x-0 -bottom-5 text-right"/>
      </div>
      <div class="relative text-left sm:text-md 2xl:text-xl">
        <label for="email"> Correo electrónico: </label>
        <Field
          type="email"
          name="email"
          class="form-input w-full mt-2 rounded-md sm:text-md 2xl:text-xl border-gray-500"
          v-model="user.email"
        />
        <ErrorMessage name="email" class="absolute text-sm text-red-600 inset-x-0 -bottom-5 text-right"/>
      </div>
      <div class="relative text-left sm:text-md 2xl:text-xl">
        <label for="password"> Contraseña: </label>
        <Field
          type="password"
          name="password"
          class="form-input w-full mt-2 rounded-md sm:text-md 2xl:text-xl border-gray-500"
          v-model="user.password"
        />
        <ErrorMessage name="password" class="absolute text-sm text-red-600 inset-x-0 -bottom-5 text-right"/>
      </div>
      <div class="relative text-left sm:text-md 2xl:text-xl">
        <label for="password_confirm"> Confirma la contraseña: </label>
        <Field
          type="password"
          name="password_confirm"
          class="form-input w-full mt-2 rounded-md sm:text-md 2xl:text-xl border-gray-500"
          v-model="user.password_confirmation"
        />
        <ErrorMessage name="password_confirm" class="absolute text-sm text-red-600 inset-x-0 -bottom-5 text-right"/>
      </div>
      <div class="relative text-left sm:text-md 2xl:text-xl">
        <label for="sucursal"> Sucursal: </label>
        <Field
          name="sucursal"
          as="select"
          class="form-select w-full mt-2 rounded-md sm:text-md 2xl:text-xl border-gray-500"
          v-model="user.sucursal"
        >
          <option class="text-gray-200" value="" disabled selected hidden> Seleccione una sucursal </option>
          <option v-for="sucursal in sucursales" :key="sucursal.suc_id" :value="sucursal.suc_id"> {{ sucursal.suc_nombre }} </option>
        </Field>
        <ErrorMessage name="sucursal" class="absolute text-sm text-red-600 inset-x-0 -bottom-5 text-right"/>
      </div>
      <div class="relative text-left sm:text-md 2xl:text-xl">
        <label for="rol"> Rol usuario: </label>
        <Field
          name="rol"
          as="select"
          class="form-select w-full mt-2 rounded-md sm:text-md 2xl:text-xl border-gray-500"
          v-model="user.rol"
        >
          <option class="text-gray-200" value="" disabled selected hidden> Seleccione un rol de usuario: </option>
          <option value="estandar"> Estandar </option>
          <option value="montaje"> Montaje </option>
          <option value="admin"> Administrador </option>
        </Field>
        <ErrorMessage name="rol" class="absolute text-sm text-red-600 inset-x-0 -bottom-5 text-right"/>
      </div>
    </div>
    <div class="flex justify-center mt-8">      
      <BaseBtn
        type="button"
        :text="userId ? 'Guardar cambios' : 'Registrar'"
        btnClass="px-4 py-4 px-6 text-white rounded-md focus:outline-none bg-green-700 hover:bg-green-600 text-lg"
        @click="handleSubmit($event, () => { showModal = true })"
      />
    </div>
    <Modal 
    v-if="showModal"
    :titulo="'¡Un momento!'"
    :pregunta="'¿Deseas continuar?'"
    >
      <template v-slot:body>
        <p class="my-3 px-8 text-justify text-xl">
          Vas a {{ userId ? 'modificar los datos del usuario' : 'registrar un nuevo usuario' }}.
        </p>
        <p class="mt-4 text-3xl font-normal">¿Deseas continuar?</p>
      </template>
      <template v-slot:botones>
        <div class="sm:flex sm:flex-row sm:justify-center">
          <BaseBtn
            type="button"
            :text="userId ? 'Guardar cambios' : 'Registrar'"
            btnClass="w-full rounded-md shadow-sm px-4 py-2 font-semibold focus:outline-none sm:w-auto sm:text-sm bg-green-700 hover:bg-green-600 text-white"
            @click="saveUser(resetForm)"
          />          
          <BaseBtn
            type="button"
            text="Cancelar"
            btnClass="mt-3 w-full rounded-md border shadow-sm px-4 py-2 font-semibold focus:outline-none sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm border-transparent bg-gray-600 hover:bg-gray-500 text-white"
            @click="showModal = false"
          />
        </div>
      </template>
    </Modal>
  </Form>
</template>

<script>
import { getError } from "@/utils/helpers"
import UserService from "@/services/UserService"
import { Field, Form, ErrorMessage } from "vee-validate"
import { object, string, number, ref } from "yup"
import Modal from "@/components/Modal"
import BaseBtn from "@/components/BaseBtn"


export default {
  name: "RegisterUserForm",
  setup(props) {
    const schema = object({
      name: string().required("El nombre es obligatorio."),
      email: string().required("El correo es obligatorio.").email("Este no es un correo válido."),
      password: !props.userId 
        ? string().required("La contraseña es un campo obligatorio.").min(8, "Debe ser de al menos 8 caractéres.")
        : string(),
      password_confirm: string().when("password", {
        is: value => value && value.length > 0,
        then: string().required("Este es un campo obligatorio.").oneOf([ref('password'), null], 'Las contraseñas deben coincidir.'),
        otherwise: string()
      }),
      sucursal: number().required("La sucursal es requerida para el nuevo usuario.").nullable(),
      rol: string().required("Debes seleccionar un rol para el nuevo usuario.").nullable(),
    })

    return {
      schema
    }
  },
  data() {
    return {
      showModal: false,
      user :{
        id: null,
        nombre: "",
        email: "",
        password: "",
        password_confirmation: "",
        sucursal: null,
        rol: null
      },
    };
  },
  computed: {
    sucursales() {
      return this.$store.getters["sucursal/sucursales"]
    }
  },
  methods: {
    saveUser(resetForm) {
      this.showModal = false
      this.error = null
      const payload = this.user
      
      if (!this.userId) {
        UserService.storeUser(payload)
         .then((response) => {
           this.emitter.emit("notification-set", {
              type: 'success',
              title: response.data.title,
              text: response.data.message,
              time: 10000,
              group: 'notifications'
            })
            resetForm()
         })
         .catch((error) => {
          this.emitter.emit("notification-set", {
            type: 'error',
            title: "Ha ocurrido un error",
            text: getError(error),
            time: 5 * 1000,
            group: 'notifications'
          })
        })
      }else {
        if(payload.password == "") delete payload.password,delete payload.password_confirmation
        UserService.updateUser(payload, this.userId)
        .then((response) => {
          this.emitter.emit("notification-set", {
            type: 'success',
            title: response.data.title,
            text: response.data.message,
            time: 0,
            group: 'notifications'
          })
        })
        .catch((error) => {
          this.emitter.emit("notification-set", {
            type: 'error',
            title: "Ha ocurrido un error",
            text: getError(error),
            time: 5 * 1000,
            group: 'notifications'
          })
        })
      }
    },
    getUserData() {
      UserService.getUser(this.userId)
      .then((response) => {
        let data = response.data.data
        this.user.nombre = data.name
        this.user.email = data.email
        this.user.sucursal = data.sucursal.id
        this.user.rol = data.role[0]
      })
      .catch((error) => this.error = getError(error))
    },
    getOffices() {
      this.error = null
    }
  },
  props: {
    userId: {
      type: Number,
      default: null
    }
  },
  mounted(){
    if (this.userId) {
      this.user.id = this.userId
      this.getUserData()
    }
    if (!this.$store.getters.sucursales) {
      this.$store.dispatch("sucursal/getSucursales")
    }
  },
  components: {
    Modal,
    BaseBtn,
    Field,
    Form,
    ErrorMessage
  }
};
</script>